/* eslint-disable */
import { lazy } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { ROUTE_PATH } from "src/constants";
import { CustomLogoLoadingSpinner } from "src/components/FairmintLogoLoadingSpinner";

import Layout from "src/layout";
import GuestGuard from "src/guards/guest-guard";
import AuthGuard from "src/guards/auth-guard";
import PortalLayout from "src/layout/portal";

import Company from "./pages/Company";
import { useDisableFairmintSpinner } from "./hooks";

const Login = lazy(() => import("src/pages/Auth/Login"));
const Signup = lazy(() => import("src/pages/Auth/Signup"));
const Portals = lazy(() => import("src/pages/Portals"));
const AdminOnboarding = lazy(() => import("src/pages/Onboarding/Signup"));
const Welcome = lazy(() => import("src/pages/Onboarding/Welcome"));
const WelcomeInvite = lazy(() => import("src/pages/Onboarding/WelcomeInvite"));
const Home = lazy(() => import("src/pages/Home"));
const Community = lazy(() => import("src/pages/Community"));
const CommunityMember = lazy(() => import("src/pages/Community/CommunityMember"));
const RiskFactors = lazy(() => import("src/pages/Fundraising/RiskFactors"));
const Investments = lazy(() => import("src/pages/Fundraising/Investments"));
const InvestmentDetail = lazy(() => import("src/pages/Fundraising/InvestmentDetail"));
const Settings = lazy(() => import("src/pages/Settings"));
const NotFound = lazy(() => import("src/pages/Error/NotFound"));
const CapTableAdmin = lazy(() => import("src/pages/CapTable/admin"));
const SeriesDeal = lazy(() => import("src/pages/CapTable/SeriesDeal"));
const SeriesPreview = lazy(() => import("src/pages/CapTable/SeriesPreview"));
const SeriesDetail = lazy(() => import("src/pages/CapTable/SeriesDetail"));
const WarrantDetail = lazy(() => import("src/pages/CapTable/WarrantDetail"));
const EquityGrants = lazy(() => import("src/pages/Grants/EquityGrants"));
const GrantDetail = lazy(() => import("src/pages/CapTable/GrantDetail"));
const EquityGrantDetail = lazy(() => import("src/pages/Grants/EquityGrantDetail"));
const Filings = lazy(() => import("src/pages/Tax/Filings"));
const Statefees = lazy(() => import("src/pages/Tax/StateFees"));
const ValuationReport = lazy(() => import("src/pages/Tax/ValuationReport"));
const ComingSoon = lazy(() => import("src/pages/ComingSoon"));
const CaptableComingSoon = lazy(() => import("src/pages/CapTable/CaptableComingSoon"));
const Dashboard = lazy(() => import("src/pages/Dashboard"));
const DataRoom = lazy(() => import("src/pages/DataRoom"));
const DataRoomList = lazy(() => import("src/pages/DataRoom/DataRoomList"));
const SeriesRoomList = lazy(() => import("src/pages/DataRoom/Series/SeriesRoomList"));
const CaptableSummary = lazy(() => import("src/pages/CaptableDashboard/SummaryView"))

const TestSpinnerWrapper = () => {
  useDisableFairmintSpinner();
  return <CustomLogoLoadingSpinner />;
};

const routes = [
  {
    path    : "auth",
    element : (
      <GuestGuard>
        <Outlet />
      </GuestGuard>
    ),
    children: [
      {
        path    : "",
        element : <Navigate to={ROUTE_PATH.LOGIN} replace />,
      },
      {
        path    : "login",
        element : <Login />,
      },
      {
        path    : "signup",
        element : <Signup />,
      },
    ],
  },
  {
    path    : "",
    element : (
      <AuthGuard>
        <Layout>
          <Outlet />
        </Layout>
      </AuthGuard>
    ),
    children: [
      {
        path    : ROUTE_PATH.PORTALS,
        element : <Portals />,
      },
      {
        path     : "p",
        children : [
          { path: "", element: <Navigate to={ROUTE_PATH.PORTALS} replace /> },
          {
            path    : ":portalId",
            element : (
              <PortalLayout>
                <Outlet />
              </PortalLayout>
            ),
            children: [
              {
                path    : "",
                element : <Home />,
              },
              {
                path    : ROUTE_PATH.ONBOARDING,
                element : <AdminOnboarding />,
              },
              {
                path    : ROUTE_PATH.WELCOME,
                element : <Welcome />,
              },
              {
                path    : ROUTE_PATH.WELCOME_INVITE,
                element : <WelcomeInvite />,
              },
              {
                path    : ROUTE_PATH.HOME,
                element : <Home />,
              },
              {
                path     : ROUTE_PATH.STAKEHOLDERS,
                children : [
                  {
                    path    : "",
                    element : <Community />,
                  },
                  {
                    path    : ":userId",
                    element : <CommunityMember />,
                  },
                ],
              },
              {
                path     : ROUTE_PATH.DASHBOARD,
                element : <Dashboard />,
              },
              {
                path    : "fundraising/risk-factors",
                element : <></>,
              },
              {
                path     : ROUTE_PATH.FUNDRAISING.INVESTMENTS,
                children : [
                  {
                    path    : "",
                    element : <Investments />,
                  },
                  {
                    path    : ":investmentId",
                    element : <InvestmentDetail />,
                  },
                ],
              },
              {
                path    : ROUTE_PATH.SETTINGS,
                element : <Settings />,
              },
              {
                path    : ROUTE_PATH.COMPANY.PROFILE,
                element : <Company />,
              },
              {
                path    : ROUTE_PATH.COMPANY.SETTINGS,
                element : <Company />,
              },
              {
                path     : "captable",
                children : [
                  { path: "overview", element: <CaptableComingSoon />},
                  { path: "admin", element: <CapTableAdmin />},
                  { path: "summary", element: <CaptableSummary />},
                  // Routes for old cap table. Will be redirected to new routes in Layout.js
                  { path: "series/:seriesId", element: <></> },
                  { path: "series-deal/:seriesId", element: <></> },
                  { path: "series-preview/:seriesId", element: <></> },
                ]

              },
              {
                path    : `${ROUTE_PATH.SERIES}/:seriesId`,
                element : <SeriesDetail />,
              },
              {
                path: `${ROUTE_PATH.SHARES}/:seriesId`,
                element: <SeriesDetail />,
              },
              {
                path: `${ROUTE_PATH.WARRANT}/:seriesId`,
                element: <WarrantDetail />,
              },
              {
                path    : `${ROUTE_PATH.GRANT}/:seriesId`,
                element : <GrantDetail/>,
              },
              {
                path    : `${ROUTE_PATH.SERIES_DEAL}/:seriesId`,
                element : <SeriesDeal />,
              },
              {
                path    : `${ROUTE_PATH.SERIES_PREVIEW}/:seriesId`,
                element : <SeriesPreview />,
              },
              {
                path     : ROUTE_PATH.GRANTS.INDEX,
                children : [
                  {
                    path    : "equity-grants",
                    element : <EquityGrants />
                  },
                  {
                    path    : "equity-grants/:grantId",
                    element : <EquityGrantDetail />
                  },
                  {
                    path    : "pools",
                    element : <ComingSoon pageName="Pools" />
                  },
                  {
                    path    : `${ROUTE_PATH.SERIES}/:seriesId`,
                    element : <SeriesDetail />,
                  },
                  {
                    path    : `${ROUTE_PATH.SERIES_DEAL}/:seriesId`,
                    element : <SeriesDeal />,
                  },
                  {
                    path    : `${ROUTE_PATH.SERIES_PREVIEW}/:seriesId`,
                    element : <SeriesPreview />,
                  },
                ],
              },
              {
                path     : ROUTE_PATH.TAX.INDEX,
                children : [
                  {
                    path    : "filings",
                    element : <Filings />
                  },
                  {
                    path    : "risk-factors",
                    element : <RiskFactors />
                  },
                  {
                    path    : "state-fees",
                    element : <Statefees />
                  },
                  {
                    path    : "409a-report",
                    element : <ValuationReport />
                  },
                ],
              },
              {
                path: ROUTE_PATH.DATA_ROOM,
                children: [
                  {
                    path: "",
                    element: <DataRoom />,
                  },
                  {
                    path: ":fileType",
                    element: <DataRoomList />,
                  },
                  {
                    path: "series/:seriesId",
                    element: <SeriesRoomList />,
                  }
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/spinner",
    element: <TestSpinnerWrapper />,
  },
  {
    path    : "*",
    element : <NotFound />,
  },
];

export default routes;
