import PropTypes from "prop-types";
import { Box, CircularProgress, alpha } from "@mui/material";
import { isSpecialEvent } from "src/App";

const LoadingSpinner = ({ inbox = false }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    zIndex={1301}
    sx={
      inbox ? {
        position        : "absolute",
        top             : 0,
        left            : 0,
        width           : "100%",
        height          : "100%",
        backgroundColor : (theme) => {
          if (isSpecialEvent()) {
            return theme.palette.common.white;
          }
          return alpha(theme.palette.primary.light, 0.2);
        }
      } : {
        position        : "fixed",
        top             : 0,
        left            : 0,
        width           : "100vw",
        height          : "100vh",
        backgroundColor : (theme) => {
          if (isSpecialEvent()) {
            return theme.palette.common.white;
          }
          return alpha(theme.palette.primary.light, 0.2);
        }
      }
    }
  >
    <CircularProgress />
  </Box>
);

LoadingSpinner.propTypes = {
  inbox: PropTypes.bool,
};

export default LoadingSpinner;
