import PropTypes from "prop-types";
import { Avatar, Box, ButtonBase, Typography, Tooltip } from "@mui/material";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { isSpecialEvent } from "src/App";

import { usePopover } from "src/hooks";
import { stringToAvatar } from "src/utils/user";
import withAccountInfo from "src/hoc/withAccountInfo";
import withUserItem from "src/hoc/withUserItem";
import { toggleThemeMode } from "src/slices/global";

import { AccountPopover } from "./account-popover";

const AccountButton = ({ userItem, portalItem }) => {
  const popover = usePopover();
  const dispatch = useDispatch();
  const themeMode = useSelector(state => state.global.themeMode);
  const isDarkMode = themeMode === "dark";

  const handleThemeToggle = (e) => {
    e.stopPropagation();
    dispatch(toggleThemeMode());
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems     : "center",
          display        : "flex",
          justifyContent : "center",
          borderWidth    : 1,
          borderStyle    : "solid",
          borderColor    : "#E5E7EB",
          borderRadius   : "12px",
          background     : isDarkMode ? "#1a1a1a" : "#fff",
          padding        : "4px 8px"
        }}
      >
        {get(portalItem, "logos.rectangle_colored") ? (
          <Box
            component="img"
            src={get(portalItem, "logos.rectangle_colored")}
            alt="logo"
            sx={{ maxHeight: "32px", height: "100%", width: "auto" }}
          />
        ) : (
          <Typography sx={{
            fontSize   : 16,
            fontWeight : "500",
            color      : isDarkMode ? "#ffffff" : "#000000CC"
          }}
          >
            {get(portalItem, "company.name").slice(0, 25)}
            {get(portalItem, "company.name").length > 25 ? "..." : ""}
          </Typography>
        )}

        <Tooltip
          title={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
          arrow
          placement="bottom"
        >
          <Box
            onClick={handleThemeToggle}
            sx={{
              marginLeft   : 1,
              padding      : "4px",
              borderRadius : "50%",
              cursor       : "pointer",
              "&:hover"    : {
                backgroundColor: "rgba(0,0,0,0.04)"
              }
            }}
          >
            {/* eslint-disable-next-line */}
            {isDarkMode ? "🌙" : (isSpecialEvent() ? "🦃" : "☀️")}
          </Box>
        </Tooltip>

        <Box
          sx={{
            justifyContent : "center",
            alignItems     : "center",
            display        : "flex",
            marginLeft     : 0.5,
            height         : 40,
            width          : 40,
            borderRadius   : "50%"
          }}
        >
          <Avatar
            sx={{
              height : 32,
              width  : 32
            }}
            {...stringToAvatar(userItem)}
          />
        </Box>
      </Box>

      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
        userItem={userItem}
        portalItem={portalItem}
      />
    </>
  );
};

AccountButton.propTypes = {
  userItem   : PropTypes.object,
  portalItem : PropTypes.object,
};

export default withUserItem(withAccountInfo(AccountButton));
