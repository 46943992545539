import { isSpecialEvent } from "src/App";
import { blue, green, indigo, purple } from "./colors";

export const getPrimary = (preset) => {
  switch (preset) {
  case "blue":
    return blue;
  case "green":
    return green;
  case "indigo":
    return indigo;
  case "purple":
    return purple;
  default:
    console.error('Invalid color preset, accepted values: "blue", "green", "indigo" or "purple"".');
    return blue;
  }
};

export const getLogoFilter = (themeMode) => {
  if (themeMode === "dark") {
    return "brightness(0) invert(1)";
  } if (isSpecialEvent()) {
    return "sepia(100%) saturate(300%) hue-rotate(350deg)";
  }
  return "none";
};
