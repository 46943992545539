import { createSlice } from "@reduxjs/toolkit";

// Add function to get initial theme from localStorage
const getInitialTheme = () => {
  const savedTheme = localStorage.getItem("themeMode");
  if (savedTheme) return savedTheme;
  return "light";
};

const initialState = {
  initialLoading : true,
  currentDialog  : {
    name  : null,
    props : null,
  },
  themeMode: getInitialTheme() // Use the function to get initial theme
};

const slice = createSlice({
  name     : "global",
  initialState,
  reducers : {
    setCurrentDialog      : (state, action) => ({ ...state, currentDialog: action.payload }),
    disableInitialLoading : state => ({ ...state, initialLoading: false }),
    toggleThemeMode       : (state) => {
      const newTheme = state.themeMode === "light" ? "dark" : "light";
      localStorage.setItem("themeMode", newTheme); // Save to localStorage
      return {
        ...state,
        themeMode: newTheme
      };
    },
    setThemeMode: (state, action) => {
      localStorage.setItem("themeMode", action.payload); // Save to localStorage
      return {
        ...state,
        themeMode: action.payload
      };
    }
  }
});

export const {
  setCurrentDialog,
  disableInitialLoading,
  toggleThemeMode,
  setThemeMode
} = slice.actions;

export default slice.reducer;
