import { useEffect, useState } from "react";
import { CircularProgress, Box, Typography, Fade } from "@mui/material";
import { useSelector } from "react-redux";

import Logo from "src/assets/images/default/FairmintLogo.svg";
import { getLogoFilter } from "src/theme/utils";

function GradientCircularProgress() {
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ "svg circle": { stroke: "url(#my_gradient)" } }} />
    </>
  );
}

export default function FairmintLogoLoadingSpinner() {
  const themeMode = useSelector(state => state.global.themeMode);
  const quotes = [
    "Cap tables: Because spreadsheets needed a midlife crisis too.",
    "Making money from managing captables is like making money from opening a bank account.",
    "If your VCs still make you use Carta, their last win was Airbnb.",
    "Equity management smoother than your CEO's pitch to VCs.",
    "If your cap table is in Excel, your exit strategy might be in jeopardy.",
    "We make cap tables sexier than your pitch deck.",
    "Cap tables shouldn't be more complex than your product.",
    "If your cap table is giving you more headaches than your Series A negotiations, you're doing it wrong.",
    "If Carta is Web 2.0, we're Web3 - minus the crypto bros and questionable JPEGs.",
    "Making stock options less mysterious than your CTO's latest tech stack decision.",
  ];

  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(Math.floor(Math.random() * quotes.length));
  const [showQuote, setShowQuote] = useState(false);

  useEffect(() => {
    const quoteTimer = setInterval(() => {
      setShowQuote(false);
      setTimeout(() => {
        setCurrentQuoteIndex(prevIndex => (prevIndex + 1) % quotes.length);
        setShowQuote(true);
      }, 1000); // Wait for fade out before changing quote
    }, 5000); // Change quote every 5 seconds

    // Show the first quote after a short delay
    const initialTimer = setTimeout(() => {
      setShowQuote(true);
    }, 500);

    return () => {
      clearInterval(quoteTimer);
      clearTimeout(initialTimer);
    };
  }, [quotes.length]);

  return (
    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <img
          src={Logo}
          alt="Fairmint logo"
          style={{
            height       : "3rem",
            marginBottom : "1rem",
            filter       : getLogoFilter(themeMode)
          }}
        />
        <GradientCircularProgress />
        <Box mt={3} height="3rem"> {/* Fixed height to prevent layout shift */}
          <Fade in={showQuote} timeout={1000}>
            <Typography fontSize="9px" sx={{ width: "250px", wordWrap: "break-word", textAlign: "center" }}>
              {quotes[currentQuoteIndex]}
            </Typography>
          </Fade>
        </Box>
      </Box>
    </Box>
  );
}

export function CustomLogoLoadingSpinner() {
  const themeMode = useSelector(state => state.global.themeMode);
  const thanksgivingQuotes = [
    "Simplifying equity, one slice at a time 🥧",
    "Your cap table's secret ingredient 📊",
    "Fresh perspectives on equity management 🍂",
    "Serving clarity in cap table management ✨",
    "Making equity management delightfully simple 🍽️",
    "Where simplicity meets sophistication 📈",
    "Smart equity solutions, served fresh daily 🥧",
    "Elevating your equity experience 🦃",
    "Clarity is our main course 🍂",
    "Excellence in every detail ✨",
  ];

  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(Math.floor(Math.random() * thanksgivingQuotes.length));
  const [showQuote, setShowQuote] = useState(false);

  useEffect(() => {
    const quoteTimer = setInterval(() => {
      setShowQuote(false);
      setTimeout(() => {
        setCurrentQuoteIndex(prevIndex => (prevIndex + 1) % thanksgivingQuotes.length);
        setShowQuote(true);
      }, 1000);
    }, 5000);

    const initialTimer = setTimeout(() => {
      setShowQuote(true);
    }, 500);

    return () => {
      clearInterval(quoteTimer);
      clearTimeout(initialTimer);
    };
  }, [thanksgivingQuotes.length]);

  const leaves = ["🍁", "🍂", "🎋"]; // Autumn leaf emojis

  return (
    <Box
      sx={{
        display        : "flex",
        justifyContent : "center",
        alignItems     : "center",
        height         : "100vh",
        background     : themeMode === "dark" ? "#1A1A1A" : "#FFFFFF",
        position       : "relative",
        overflow       : "hidden" // Hide leaves that fall outside
      }}
    >
      {/* Falling Leaves Animation - Lower z-index */}
      <Box
        sx={{
          position : "absolute",
          inset    : 0,
          zIndex   : 1
        }}
      >
        {[...Array(12)].map((_, index) => (
          <Box
            key={`leaf-${index}`}
            component="span"
            sx={{
              position          : "absolute",
              top               : -20,
              left              : `${Math.random() * 100}%`,
              fontSize          : `${Math.random() * (24 - 16) + 16}px`,
              animation         : `fall ${Math.random() * (20 - 10) + 10}s linear infinite`,
              animationDelay    : `-${Math.random() * 20}s`,
              opacity           : 0.7,
              transform         : "rotate(0deg)",
              "@keyframes fall" : {
                "0%": {
                  transform : "translateY(-20px) rotate(0deg)",
                  opacity   : 0.7
                },
                "50%": {
                  transform : `translateY(50vh) rotate(${180 + (Math.random() * 360)}deg)`,
                  opacity   : 0.9
                },
                "100%": {
                  transform : `translateY(100vh) rotate(${360 + (Math.random() * 360)}deg)`,
                  opacity   : 0.7
                }
              }
            }}
          >
            {leaves[index % leaves.length]}
          </Box>
        ))}
      </Box>

      {/* Main content - Higher z-index */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          padding      : "2rem",
          borderRadius : "16px",
          position     : "relative",
          zIndex       : 2  // Higher than leaves
        }}
      >
        <Box
          sx={{
            position: "relative",
            // mb       : 3
          }}
        >
          <img
            src={Logo}
            alt="Fairmint logo"
            style={{
              height : "2.5rem",
              filter : getLogoFilter(themeMode)
            }}
          />
        </Box>

        <Box mt={2} display="flex" gap={2}>
          {["turkey1", "turkey2", "turkey3"].map((id, i) => (
            <Box
              key={id}
              component="span"
              sx={{
                animation          : "float 3s ease-in-out infinite",
                animationDelay     : `${i * 0.5}s`,
                fontSize           : "2rem",
                "@keyframes float" : {
                  "0%, 100%": {
                    transform: "translateY(0)"
                  },
                  "50%": {
                    transform: "translateY(-15px)"
                  }
                }
              }}
            >
              🦃
            </Box>
          ))}
        </Box>

        <Box mt={3} height="3rem">
          <Fade in={showQuote} timeout={1000}>
            <Typography
              fontSize="10px"
              sx={{
                width      : "300px",
                wordWrap   : "break-word",
                textAlign  : "center",
                color      : themeMode === "dark" ? "#E67E22" : "#D35F0A",
                // fontFamily : "'EB Garamond', serif", // More traditional, harvest-themed font
                fontWeight : 500,
                lineHeight : 1.6,
                transition : "all 0.3s ease-in-out",
                "&:hover"  : {
                  color     : themeMode === "dark" ? "#FF8C00" : "#A0522D",
                  transform : "scale(1.02)"
                }
              }}
            >
              {thanksgivingQuotes[currentQuoteIndex]}
            </Typography>
          </Fade>
        </Box>
      </Box>
    </Box>
  );
}
